import { MerchantType } from '@/models/merchant';
import { RouteLocationRaw } from 'vue-router';
import { MIGRATE_STATUS } from '@/constants';
import i18n from '@/i18n';
import _ from 'lodash';

const t = i18n.global.t;

interface IStep {
  title: string;
  route: RouteLocationRaw;
  status: Array<keyof typeof MIGRATE_STATUS>
}

export function getStoreMigrationSteps(merchant: MerchantType): IStep[] {
  return [
    {
      title: t('store_migration.store_setup'),
      route: { name: 'store-migration.store-setup', params: { id: merchant.id } },
      status: ['already_setup_store', 'pending'] as Array<keyof typeof MIGRATE_STATUS>,
      if: () => true
    },
    {
      title: t('store_migration.add_policy_links'),
      route: { name: 'store-migration.add-policy-links', params: { id: merchant.id } },
      status: ['added_policy', 'already_setup_store'] as Array<keyof typeof MIGRATE_STATUS>,
      if: () => true
    },
    {
      title: t('store_migration.add_payment_method'),
      route: { name: 'store-migration.add-payment-method', params: { id: merchant.id } },
      status: ['added_payment_method', 'added_policy'] as Array<keyof typeof MIGRATE_STATUS>,
      if: () => true
    },
    {
      title: t('store_migration.customize_theme'),
      route: { name: 'store-migration.customize-theme', params: { id: merchant.id } },
      // store setup 步骤 没选migrate_from时，这是最后步骤
      status: ['added_theme', 'added_payment_method'].concat(merchant.migrate_from === 'unknown' ? ['completed'] : []) as Array<keyof typeof MIGRATE_STATUS>,
      if: () => true
    },
    {
      title: t('store_migration.products_migration'),
      route: { name: 'store-migration.products-migration', params: { id: merchant.id } },
      status: ['added_theme', 'fetching_sync_data', 'sync_data_fetched', 'syncing', 'completed'] as Array<keyof typeof MIGRATE_STATUS>,
      // store setup 步骤 选了migrate_from时，这是最后步骤
      if: () => merchant.migrate_from !== 'unknown'
    }
  ].filter(step => step.if());
}

export function getActiveStoreMigrationStepIndex(merchant: MerchantType) {
  return _.findLastIndex(
    getStoreMigrationSteps(merchant),
    step => step.status.includes(merchant.migrate_status)
  );
}

export function getActiveStoreMigrationStep(merchant: MerchantType) {
  return _.findLast(
    getStoreMigrationSteps(merchant),
    step => step.status.includes(merchant.migrate_status)
  );
}

export function getStoreMigrationProgress(merchant: MerchantType) {
  if (merchant.migrate_status === 'completed') {
    return 100;
  }
  return Math.floor(getActiveStoreMigrationStepIndex(merchant) / getStoreMigrationSteps(merchant).length * 100);
}
