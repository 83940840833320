export default {
  'store_setup': '店铺设置',
  'add_policy_links': '添加协议',
  'add_payment_method': '添加支付方式',
  'customize_theme': '安装主题',
  'products_migration': '迁移建站',
  'create_for': '主题分类',
  'themes_empty': '没有找到主题',
  'bulk_update_price_label': '批量修改价格',
  'migration_progress': '迁移进度',
  'yes': '是',
  'no': '否',
  'migrate_tip': '提示：在进行产品迁移之前，请确保您已授权这些平台',
  'migrate_from_label': '迁移自',
  'migrate_to_label': '迁移到',
  'overall_adjustment_tip': '提示：请确保您在点击迁移之前仔细检查了这部分',
  'send_auth_email': '发送授权邮件',
  'authorized': '{name}已授权',
  'not_authorized': '{name} 授权未完成',
  'auth_step_tip': '请按照这些步骤完成创建',
  'auth_to': '授权{name}店铺',
  'check_auth_status': '检查授权状态',
  'enter_site_address': '请填写站点网址',
  'use_default_template': '使用默认模板',
  'use_my_own_template': '使用我创建的模板',
  'templates_empty': '没有找到模板',
  'policy_content_tip': '红色高亮为自动替换的商家信息，高亮不会显示在迁移目标店铺',
  'confirm_replace_content': '确定替换已输入的内容？',
  'custom_var': '自定义字段',
  'about_us': '关于我们',
  'contact_us': '联系我们',
  'ai_optimize_product_title': 'AI缩短标题',
  'product_image_resize_width': '图片宽',
  'product_image_resize_height': '图片高',
  'product_image_resize': '裁剪产品图片',
  'cancel_product_image_resize': '取消产品图片裁剪',
  'product_image_resize_info': '产品图片将被裁剪成大小 {width} x {height}',
  'select_theme': '选择主题',
  'suggest_width': '建议宽度 2048，高度根据宽度和图片比例填写',
  'recommend_themes': '推荐主题',
  'other_themes': '其他主题',
  'payment_methods': '支付方式',
  'all_migrate': '全部商品迁移',
  'partial_migrate': '部分商品迁移',
  selectMigrateItems: {
    title: '选择需要迁移的商品',
    selected: '已选择: {count}',
    select: '选择',
    cancelSelect: '取消选择',
    startFetchSyncData: '开始同步商品',
    selectItems: '选择商品',
    startSync: '开始迁移',
    alreadyMigrated: '已迁移',
    name: '名称',
    sku: 'SKU',
    asin: 'ASIN',
    search: '搜索',
    fetchingSyncData: '正在同步商品数据，大约需要 5 ~ 10分钟，请耐心等待',
  },
};
