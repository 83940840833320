import { useAsyncState, UseAsyncStateReturn, UseAsyncStateOptions } from '@vueuse/core';

export function useAsync<Data>(
  promise: Promise<Data> | ((...args: any[]) => Promise<Data>)
): UseAsyncStateReturn<Data | undefined, any[], true>;
export function useAsync<Data>(
  promise: Promise<Data> | ((...args: any[]) => Promise<Data>),
  initialState: Data
): UseAsyncStateReturn<Data, any[], true>;
export function useAsync<Data, Shallow extends boolean = true>(
  promise: Promise<Data> | ((...args: any[]) => Promise<Data>),
  initialState: Data,
  options: UseAsyncStateOptions<Shallow, Data>
): UseAsyncStateReturn<Data, any[], Shallow>;
export function useAsync<Data, Shallow extends boolean = true>(
  promise: Promise<Data> | ((...args: any[]) => Promise<Data>),
  initialState?: Data | undefined,
  options?: UseAsyncStateOptions<Shallow, Data | undefined>
) {
  return useAsyncState(promise, initialState, {
    immediate: false,
    throwError: true,
    ...options
  });
}
