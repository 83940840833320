export default {
  title: 'Blog articles',
  createBlog: 'Create blog article',
  updateBlog: 'Update blog article',
  blogDetail: 'Blog article detail',
  push: 'Push',
  asin: {
    label: 'ASIN',
    hint: 'The product you want to promote through SEO articles. If no options are available, you can enter the ASIN and press Enter to fill in.'
  },
  link: {
    label: 'Link',
    hint: 'The link (URL) of your listing, or your affiliate link'
  },
  imagePosition: {
    label: 'Image',
    hint: 'Choose one image from your listing'
  },
  primaryKeywords: {
    label: 'Primary Keyword',
    hint: 'Enter 1-2 keywords about your products, e.g. Golf balls, side tables, etc.'
  },
  language: {
    label: 'Language',
    hint: 'Target language of your blog, e.g. English, French, German, etc.'
  },
  state: {
    label: 'State'
  },
  blogTitle: {
    label: 'Title',
  },
  content: {
    label: 'Content'
  },
  shopifyBlogId: {
     label: 'Select Shopify blog',
    hint: 'If not selected, it will be automatically created.'
  },
  author: {
    label: 'Author',
  },
  image: {
    label: 'Image',
  },
  summary: {
    label: 'Summary',
    hint: 'Add a summary to display on the homepage or blog.'
  },
  tag: {
    label: 'Tag',
  },
  marketplace: {
    label: 'Amazon Marketplace',
  }
};
