export default {
  'home': '首页',
  'merchants_management': '商户管理',
  'templates_management': '模板管理',
  'store_migration': '迁移建站',
  'google_integration': '追踪代码',
  'theme_app_extensions_instruction': '主题应用扩展',
  'help_center': '帮助中心',
  'agencies_management': '代理人管理',
  'appeal_record_management': '申诉记录管理',
  'campaign': '广告创建',
  'review': '评论管理',
  'reporting': '数据面板与建议',
  'metaobject_swatch': '颜色色卡',
  'metaobject_size_table': '尺寸表',
  'store_settings_and_migration': '店铺迁移与设置',
  'arit': '网站检测',
  'reporting_data': '营销报告',
  'reporting_suggestion': '优化项目',
  'optional_menu': '可选配置',
  'gads': '广告',
  'googleUserLists': '客户名单',
  'blogs': '博客文章',
  'tamar_ai_accounts': '亚马逊店铺选品',
  'pmax_campaign_guide': '效果最大化',
  'blog_guide': '博客指南',
  'campaign_guide': '广告创建指南',
  'shopping_campaign_guide': '购物广告',
  'search_campaign_guide': '搜索广告',
  'tamar_ai_account_guide': '亚马逊店铺选品指南',
  'poi_insights_report': '非亚马逊行业报告',
  'pre_sale_toolbox': '售前工具箱',
  'pre_sale_toolbox_guide': '售前工具箱指南'
};
