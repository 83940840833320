import { ref, reactive, onMounted } from 'vue';
import { request } from '@/utils';
import { useRoute, useRouter } from 'vue-router';
import _ from 'lodash';

interface IUseCollectionOptions {
  defaultParams?: {[key: string]: any };
  changeRoute?: boolean;
}

export function useCollection<T>(resource: string, { defaultParams = { per_page: 20, page: 1 }, changeRoute = true }: IUseCollectionOptions = {}) {
  const data = ref<T[]>([]);
  const loading = ref(false);
  const pagination = reactive({
    total: 0,
    'page-size': 20,
    'current-page': 1
  });
  const route = useRoute();
  const router = useRouter();
  async function fetchData(params: {[key: string]: any} = {}) {
    loading.value = true;
    try {
      const result = await request.get<any>(resource, { params: { ...defaultParams, ...(changeRoute ? route.query : {}), ...params } });
      data.value = result.data;
      Object.assign(pagination, {
        total: Number(result.headers['x-total']),
        'page-size': Number(result.headers['x-per-page']),
        'current-page': Number(result.headers['x-page']),
      });
    } finally {
      loading.value = false;
    }
  }

  onMounted(fetchData);

  function onPageChange(page: number) {
    const fetchParams = { ...route.query, page };
    if (changeRoute) {
      router.replace({ query: fetchParams });
    }
    fetchData({ page });
  }

  function onSearch(params: {[key: string]: any}) {
    const fetchParams = { ...route.query, page: 1, ..._.mapValues(params, (value) => _.isEmpty(value) ? undefined : value) };
    if (changeRoute) {
      router.replace({ query: fetchParams });
    }
    fetchData(fetchParams);
  }

  return {
    loading,
    data,
    pagination,
    fetchData,
    onPageChange,
    onSearch,
  };
}
