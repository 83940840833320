import { onBeforeUnmount } from 'vue';

export function usePolling(fn: () => Promise<any>, interval: number) {
  let timer: ReturnType<typeof setInterval>;
  onBeforeUnmount(stopPolling);
  function startPolling() {
    stopPolling();
    timer = setInterval(() => {
      fn();
    }, interval);
  }

  function stopPolling() {
    clearInterval(timer);
  }
  return {
    startPolling,
    stopPolling,
  };
}
