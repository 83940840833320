export default {
  'name': 'Name',
  'company_name': 'Company Name',
  'email': 'Email',
  'brand_name': 'Brand Name',
  'phone_number': 'Phone Number',
  'industry': 'Industry',
  'company_address': 'Company Address',
  'company_established': 'Company Established',
  'return_address': 'Return Address',
  'return_contact': 'Return Contact',
  'return_phone_number': 'Return Phone Number',
  'return_validity_time': 'Return Validity Time (days)',
  'shop_subdomain': 'Shop Sub Domain',
  'return_email': 'Return Email',
  'auto_sync': 'Auto sync',
  'enable_auto_sync': 'Enable auto sync',
  'disable_auto_sync': 'Disable auto sync',
  'assignAgent': 'Assigning agents',
  'agents': 'Agents',
  'btn_import_merchant': 'Import merchants',
  'import_step_1_tip': 'Step 1: Please export all won opportunities from CRM [Sales - Opportunities] as a .xlsx file.',
  'import_step_2_tip': 'Step 2: Have the operations team upload the exported .xlsx file to create merchants in bulk.',
  'form_link': {
    'title': 'Merchant Information Form Link',
    'expire_tip': 'This link is valid <strong>within 24 hours</strong>',
    'copy_link': 'Copy Link',
    'get_link': 'Get Merchant Information Form Link'
  },
  'product_type': 'Product Type',
  'product_type_placeholder': 'Please fill in. For example: silk dress',
  'product_type_tip': 'Please fill in at most one detailed sub-category of a product, generated with the help of AI. The more precise, the better. For example: silk dress / knitted women\'s clothing / wooden furniture, up to 10 characters, no punctuation or spaces',
  'currency': 'Currency',
  'brand_name_tip': 'Please fill in the English name',
  'poi_insights_report': {
    'generate': 'Generate Industry Report',
    'generating': 'Generating Industry Report',
    're_generate': 'Re-generate Industry Report',
    'download': 'Download Industry Report',
    'generating_success': 'Generating Industry Report, it will take about 10 minutes, you can leave this page and come back later to download the report at the bottom of the page',
    'generate_tip': 'Please click the button at the bottom of the page to generate the report'
  },
  'customer_contact_phone': 'Customer Contact Phone',
  'customer_contact_email': 'Customer Contact Email',
  'order_handle_days': {
    label: 'Order Handle Days',
    hint: 'The number of days to process orders after receiving them'
  },
  'need_pay_return_amount': 'Need Pay Return Amount'
};
