export default {
  title: 'Pre-sale Toolbox',
  categoryInsightsReport: {
    title: 'Category Insights Report',
    description: 'Help analyze the market performance of subcategories',
    category: 'Category',
    createByExcel: 'Create by Excel',
    importTemplate: 'Download Excel Template',
    createByInput: 'Create by Input',
    createTitle: 'Create Category Insights Report',
    notification: {
      success: 'Operation successful, please wait for data processing to complete'
    },
    status: 'Status',
    operator: 'Operator',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    file: 'File',
    batchDownload: 'Batch Download Report',
    download: 'Download Report'
  }
};
