export default {
  title: '售前工具箱',
  categoryInsightsReport: {
    title: '细分品类洞察报告',
    description: '帮助分析细分品类市场表现',
    category: '细分行业',
    createByExcel: '从Excel创建',
    selectFile: '选择文件',
    importTemplate: '下载Excel模板',
    createByInput: '输入细分行业创建',
    createTitle: '创建细分行业洞察报告',
    notification: {
      success: '操作成功，请等待数据处理完成'
    },
    status: '状态',
    operator: '操作人',
    createdAt: '创建时间',
    updatedAt: '更新时间',
    file: '报告',
    batchDownload: '批量下载报告',
    download: '下载报告'
  }
};

