import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { gadsRoute } from './gads';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: require('@/layouts/auth-layout.vue').default,
    children: [
      {
        path: '',
        name: 'home',
        component: require('@/views/home.vue').default,
      },
      {
        path: '/change-password',
        name: 'change-password',
        component: require('@/views/change-pass.vue').default,
      },
      {
        path: '/profile',
        name: 'profile',
        component: require('@/views/profile.vue').default,
      },
      {
        path: '/merchants/:id/migration',
        component: require('@/layouts/merchant-sidebar-layout.vue').default,
        children: [
          {
            path: 'store-migration',
            component: require('@/views/store-migration/layouts/index.vue').default,
            children: [
              {
                path: '',
                name: 'store-migration',
                component: require('@/views/store-migration/index.vue').default,
                meta: {
                  contentHeightFixed: true
                }
              },
              {
                path: 'store-setup',
                name: 'store-migration.store-setup',
                component: require('@/views/store-migration/store-setup.vue').default,
                meta: {
                  contentHeightFixed: true
                }
              },
              {
                path: 'add-policy-links',
                name: 'store-migration.add-policy-links',
                component: require('@/views/store-migration/add-policy-links.vue').default,
                meta: {
                  contentHeightFixed: true
                }
              },
              {
                path: 'add-payment-method',
                name: 'store-migration.add-payment-method',
                component: require('@/views/store-migration/add-payment-method.vue').default,
                meta: {
                  contentHeightFixed: true
                }
              },
              {
                path: 'customize-theme',
                name: 'store-migration.customize-theme',
                component: require('@/views/store-migration/customize-theme.vue').default,
                meta: {
                  contentHeightFixed: true
                }
              },
              {
                path: 'customize-theme/:themeId',
                name: 'store-migration.customize-theme.show',
                component: require('@/views/store-migration/theme.vue').default,
                meta: {
                  contentHeightFixed: true
                }
              },
              {
                path: 'products-migration',
                name: 'store-migration.products-migration',
                component: require('@/views/store-migration/products-migration.vue').default,
                meta: {
                  contentHeightFixed: true
                }
              }
            ]
          },
          {
            path: 'customize-theme',
            name: 'customize-theme',
            component: require('@/views/store-migration/customize-theme.vue').default,
          },
          {
            path: 'customize-theme/:themeId',
            name: 'customize-theme.show',
            component: require('@/views/store-migration/theme.vue').default
          },
          {
            path: 'google-integration',
            component: require('@/views/google-integration/layouts/index.vue').default,
            children: [
              {
                path: '',
                name: 'google-integration',
                component: require('@/views/google-integration/index.vue').default,
                meta: {
                  contentHeightFixed: true
                }
              },
              {
                path: 'shoplazza',
                name: 'shoplazza-google-integration',
                component: require('@/views/google-integration/shoplazza.vue').default,
                meta: {
                  contentHeightFixed: true
                }
              },
              {
                path: 'account-setup',
                name: 'google-integration.account-setup',
                component: require('@/views/google-integration/account-setup.vue').default,
                meta: {
                  contentHeightFixed: true
                }
              },
              {
                path: 'checkout-page-tracking',
                name: 'google-integration.checkout-page-tracking',
                component: require('@/views/google-integration/checkout-page-tracking.vue').default,
                meta: {
                  contentHeightFixed: true
                }
              },
              {
                path: 'install-app',
                name: 'google-integration.install-app',
                component: require('@/views/google-integration/install-app.vue').default,
                meta: {
                  contentHeightFixed: true
                }
              }
            ]
          },
          {
            path: 'migration-tasks',
            component: require('@/views/migration-tasks/index.vue').default,
            name: 'migration-tasks',
            meta: {
              contentHeightFixed: true
            }
          },
          {
            path: 'migration-tasks/:migrationTaskId',
            component: require('@/views/migration-tasks/show.vue').default,
            name: 'migration-tasks.show',
          },
          ...gadsRoute,
          {
            path: 'reviews',
            component: require('@/layouts/shopify-auth-layout.vue').default,
            props: {
              scope: ['unauthenticated_read_product_listings'],
            },
            children: [
              {
                path: '',
                component: require('@/views/reviews/index.vue').default,
                name: 'reviews',
                meta: {
                  contentHeightFixed: true
                }
              },
              {
                path: 'new',
                component: require('@/views/reviews/form.vue').default,
                name: 'reviews.create',
                meta: {
                  routerViewCardWrapper: false,
                },
              },
              {
                path: ':reviewId/edit',
                component: require('@/views/reviews/form.vue').default,
                name: 'reviews.edit',
                meta: {
                  routerViewCardWrapper: false,
                },
              },
              {
                path: ':reviewId',
                component: require('@/views/reviews/show.vue').default,
                name: 'reviews.show'
              },
            ]
          },
          {
            path: 'metaobject',
            component: require('@/layouts/shopify-auth-layout.vue').default,
            props: {
              scope: ['write_metaobject_definitions', 'write_metaobjects', 'write_files'],
            },
            children: [
              {
                path: 'swatch',
                name: 'metaobject.swatch',
                component: require('@/views/metaobject/swatch.vue').default,
              },
              {
                path: 'size_table',
                name: 'metaobject.sizeTable',
                component: require('@/views/metaobject/size-table.vue').default,
              },
            ]
          },
          {
            path: 'reporting/data',
            name: 'reporting.data',
            component: require('@/views/reporting.vue').default,
            props: {
              url: 'https://lookerstudio.google.com/embed/reporting/65a87701-dbee-470f-866f-05770612222c/page/YoQ0D'
            },
            meta: {
              contentHeightFixed: true,
              routerViewCardWrapper: false,
            }
          },
          {
            path: 'reporting/suggestion',
            name: 'reporting.suggestion',
            component: require('@/views/reporting.vue').default,
            props: {
              url: 'https://lookerstudio.google.com/embed/u/0/reporting/65a87701-dbee-470f-866f-05770612222c/page/p_4mc9i9x0hd'
            },
            meta: {
              contentHeightFixed: true,
              routerViewCardWrapper: false,
            }
          },
          {
            path: 'arits',
            name: 'arits',
            component: require('@/views/arits/index.vue').default,
            meta: {
              contentHeightFixed: true,
            }
          },
          {
            path: 'arits/new',
            name: 'arits.create',
            component: require('@/views/arits/new.vue').default,
          },
          {
            path: 'blogs',
            component: require('@/layouts/shopify-auth-layout.vue').default,
            props: {
              scope: ['write_content'],
            },
            children: [
              {
                path: '',
                component: require('@/views/blogs/index.vue').default,
                name: 'blogs.index',
                meta: {
                  contentHeightFixed: true,
                }
              },
              {
                path: ':blogId',
                component: require('@/views/blogs/show.vue').default,
                name: 'blogs.show',
              },
              {
                path: 'new',
                component: require('@/views/blogs/form.vue').default,
                name: 'blogs.create'
              },
            ]
          },
          {
            path: 'tamar-ai-accounts',
            component: {
              template: '<router-view />'
            },
            children: [
              {
                path: '',
                name: 'tamar-ai-accounts.index',
                component: require('@/views/tamar-ai-accounts/index.vue').default,
                meta: {
                  contentHeightFixed: true
                }
              },
              {
                path: 'new',
                name: 'tamar-ai-accounts.new',
                component: require('@/views/tamar-ai-accounts/form.vue').default,
              },
              {
                path: ':tamarAiAccountId',
                name: 'tamar-ai-accounts.show',
                component: require('@/views/tamar-ai-accounts/show.vue').default,
              },
              {
                path: ':tamarAiAccountId/add-store',
                name: 'tamar-ai-accounts.add-store',
                component: require('@/views/tamar-ai-accounts/add-store.vue').default,
              },
              {
                path: ':tamarAiAccountId/auth-success',
                name: 'tamar-ai-accounts.auth-success',
                component: require('@/views/tamar-ai-accounts/auth-success.vue').default,
              }
            ],
          },
        ]
      },
      {
        path: '',
        component: require('@/layouts/main-sidebar-layout.vue').default,
        children: [
          {
            path: '/agencies',
            name: 'agencies.index',
            component: require('@/views/agencies/index.vue').default,
            meta: {
              contentHeightFixed: true
            }
          },
          {
            path: '/agencies/:id',
            name: 'agencies.show',
            component: require('@/views/agencies/show.vue').default,
          },
          {
            path: '/agencies/:id/edit',
            name: 'agencies.edit',
            component: require('@/views/agencies/form.vue').default,
          },
          {
            path: '/agencies/new',
            name: 'agencies.new',
            component: require('@/views/agencies/form.vue').default,
          },
          {
            path: '/merchants',
            name: 'merchants.index',
            component: require('@/views/merchants/index.vue').default,
            meta: {
              contentHeightFixed: true
            }
          },
          {
            path: '/merchants/:id',
            name: 'merchants.show',
            component: require('@/views/merchants/show.vue').default,
            meta: {
              contentHeightFixed: true,
              routerViewCardWrapper: false,
            }
          },
          {
            path: '/merchants/:id/edit',
            name: 'merchants.edit',
            component: require('@/views/merchants/form.vue').default,
          },
          {
            path: '/merchants/new',
            name: 'merchants.new',
            component: require('@/views/merchants/form.vue').default,
          },
          {
            path: '/policy-templates',
            name: 'policy-templates.index',
            component: require('@/views/policy-templates/index.vue').default,
            meta: {
              contentHeightFixed: true
            }
          },
          {
            path: '/policy-templates/new',
            name: 'policy-templates.new',
            component: require('@/views/policy-templates/form.vue').default,
          },
          {
            path: '/policy-templates/:id/edit',
            name: 'policy-templates.edit',
            component: require('@/views/policy-templates/form.vue').default,
          },
          {
            path: '/help-center/:type',
            name: 'help-center',
            component: require('@/views/help-center.vue').default,
          },
          {
            path: '/appeal-records',
            name: 'appeal-records',
            component: require('@/views/appeal-records/index.vue').default,
            meta: {
              contentHeightFixed: true
            }
          },
          {
            path: '/appeal-records/new',
            name: 'appeal-records.new',
            component: require('@/views/appeal-records/form.vue').default,
            meta: {
              contentHeightFixed: true
            }
          },
          {
            path: '/appeal-records/:id/edit',
            name: 'appeal-records.edit',
            component: require('@/views/appeal-records/form.vue').default,
            meta: {
              contentHeightFixed: true
            }
          },
          {
            path: '/appeal-records/:id',
            name: 'appeal-records.show',
            component: require('@/views/appeal-records/show.vue').default,
            meta: {
              contentHeightFixed: true
            }
          },
          {
            path: '/appeal-letter-flow-guide',
            name: 'appeal-letter-flow-guide',
            component: require('@/views/appeal-letter-flow-guide.vue').default,
            meta: {
              contentHeightFixed: true
            }
          },
          {
            path: '/category-insights-reports',
            name: 'category-insights-reports',
            component: require('@/views/category-insights-reports/index.vue').default,
            meta: {
              contentHeightFixed: true
            }
          },
          {
            path: '/category-insights-reports/:id',
            name: 'category-insights-reports.show',
            component: require('@/views/category-insights-reports/show.vue').default,
          }
        ]
      },
    ]
  },
  {
    path: '',
    component: require('@/layouts/common-layout.vue').default,
    children: [
      {
        path: '/login',
        name: 'login',
        component: require('@/views/login.vue').default,
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        component: require('@/views/reset-pass.vue').default,
      },
      {
        path: '/auth-amazon/:uuid',
        name: 'auth-amazon',
        component: require('@/views/auth-amazon.vue').default,
      },
      {
        path: '/auth-success',
        name: 'auth-success',
        component: require('@/views/auth-success.vue').default,
      },
      {
        path: '/:pathMatch(.*)',
        name: '404',
        component: require('@/views/404.vue').default,
      },
    ]
  },
  {
    path: '/frontend',
    component: require('@/layouts/frontend-layout.vue').default,
    children: [
      {
        path: 'merchants/:id',
        component: require('@/layouts/merchant-frontend-layout.vue').default,
        children: [
          {
            path: 'form',
            name: 'frontend.merchant.form',
            component: require('@/views/merchants/frontend-form.vue').default,
          },
          {
            path: 'result',
            name: 'frontend.merchant.result',
            component: require('@/views/merchants/frontend-form-result.vue').default,
          },
          {
            path: 'tamar-ai-accounts/:tamarAiAccountId',
            name: 'frontend.tamar-ai-accounts.show',
            component: require('@/views/tamar-ai-accounts/show.vue').default,
          },
          {
            path: 'tamar-ai-accounts/new',
            name: 'frontend.tamar-ai-accounts.new',
            component: require('@/views/tamar-ai-accounts/form.vue').default,
          },
          {
            path: 'tamar-ai-accounts/:tamarAiAccountId/add-store',
            name: 'frontend.tamar-ai-accounts.add-store',
            component: require('@/views/tamar-ai-accounts/add-store.vue').default,
          },
          {
            path: 'tamar-ai-accounts/:tamarAiAccountId/auth-success',
            name: 'frontend.tamar-ai-accounts.auth-success',
            component: require('@/views/tamar-ai-accounts/auth-success.vue').default,
          }
        ]
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_ROUTER_BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
