export default {
  '1688': '1688',
  'amazon': 'Amazon',
  'shopify': 'Shopify',
  'shoplazza': 'Shoplazza',
  'migrate_from': {
    'unknown': 'I don’t need migration'
  },
  'migrate_to': {
    'unknown': 'Unknown'
  },
  'policy_type': {
    'refund_policy': 'Refund Policy',
    'privacy_policy': 'Privacy Policy',
    'terms_of_service': 'Terms of Service ',
    'shipping_policy': 'Shipping Policy'
  },
  'migration_status': {
    'pending': 'Pending',
    'already_setup_store': 'Already Setup Store',
    'added_policy': 'Added Policy',
    'added_payment_method': 'Added Payment Method',
    'added_theme': 'Added Theme',
    'syncing': 'Syncing',
    'completed': 'Completed',
    'fetching_sync_data': 'Fetching Sync Data',
    'sync_data_fetched': 'Sync Data Fetched'
  },
  'overall_adjustment_price_type': {
    'increase_percentage': 'Increase Percentage',
    'decrease_percentage': 'Decrease Percentage'
  },
  'migrate_task_status': {
    'pending': 'Pending',
    'processing': 'Processing',
    'completed': 'Completed'
  },
  'google_integrate_status': {
    'pending': 'Pending',
    'added_google_channel': 'Added Google Channel',
    'added_tracking_code': 'Added Tracking Code',
    'completed': 'Completed'
  },
  'automation_comaign_state': {
    'pending': 'Pending',
    'processing': 'Processing',
    'completed': 'Completed'
  },
  'appeal_target_gender': {
    'male': 'Male',
    'female': 'Female',
    'unknown': 'Unknown'
  },
  'appeal_target_income': {
    'low': 'Low income',
    'mid': 'Medium income',
    'high': 'High income'
  },
  'appeal_reason': {
    'gmc_block': 'GMC account suspension',
    'not_comply_terms_and_conditions': 'Advertising account suspended - Non-compliance with terms and conditions',
    'other': 'Advertising account suspended - Other'
  },
  'appeal_generate_content_state': {
    'pending': 'Pending',
    'processing': 'Processing',
    'completed': 'Completed'
  },
  'campaign_types': {
    'Search': {
      'title': 'Search',
      'subtitle': 'Get in front of high-intent customers at the right time on Google Search'
    },
    'Shopping': {
      'title': 'Shopping',
      'subtitle': 'Showcase your products to shoppers as they explore what to buy'
    },
    'PMax': {
      'title': 'Performance Max',
      'subtitle': 'Reach audiences across all of Google with a single campaign.'
    }
  },
  'shopping_campaign_bid_strategy_types': {
    'maximize_clicks': {
      'label': 'Maximize clicks',
      'descs': [
        'With "Maximize clicks", Google Ads automatically sets your bids to help get as many clicks as possible within your budget',
        'A maximum CPC bid limit caps each cost-per-click bid. This could limit the clicks on your ad.',
        'The strategy you select here will be applied to this campaign only.'
      ]
    },
    'target_ROAS': {
      'label': 'Target ROAS',
      'descs': [
        'With "Target ROAS", Google Ads automatically sets bids to help get as much conversion value as possible at the target return on ad spend(ROAS) you set. Some conversions may have a higher or lower return than your target.',
        'The strategy you select here will be applied to this campaign only.'
      ]
    },
    'manual_CPC': {
      'label': 'Manual CPC',
      'descs': [
        'With "Manual CPC" bidding, you set your own maximum cost-per-click (CPC) for your ads.'
      ]
    }
  },
  'review_state': {
    'hide': 'Hide',
    'published': 'Published'
  },
  'boolean_select_map': {
    'true': 'Yes',
    'false': 'No'
  },
  pmaxCampaignBidStrategyTypes: {
    maximizeConversions: 'Maximize Conversions',
    maximizeConversionValue: 'Maximize Conversion Value',
  },
  googleUserListsStatus: {
    pending: 'Pending',
    running: 'Running',
    success: 'success',
    failed: 'Failed',
  },
  blogState: {
    pending: 'Pending',
    processing: 'Processing',
    generated: 'Generated',
    pushing: 'Pushing',
    pushed: 'Pushed',
  },
  tamarAiAccountReportStatus: {
    pending: 'Pending',
    in_progress: 'In Progress',
    completed: 'Completed',
    failed: 'Failed',
  },
  tamarAiAccountStoreType: {
    seller: 'Regular Seller Store',
    vendor: 'VC Store',
  },
  tamarAiAccountRegion: {
    NA: 'North America',
    EU: 'Europe',
    FE: 'Far East',
  },
  poiInsightsReportStatus: {
    pending: 'Pending',
    in_progress: 'In Progress',
    completed: 'Completed',
    failed: 'Failed',
  },
  subcategoryInsightReportStatus: {
    pending: 'Pending',
    processing: 'Processing',
    completed: 'Completed',
  },
};
