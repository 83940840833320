import { MerchantType } from '@/models/merchant';
import { request } from '@/utils/request';
import { ElMessageBox } from 'element-plus';
import { type Ref, computed, watch, onBeforeUnmount, ref } from 'vue';
import { polling, downloadFromUrl } from '@/utils';
import { useI18n } from 'vue-i18n';

export function usePoiInsightsReport(merchant: Ref<MerchantType>, fetchData: () => Promise<MerchantType>) {
  const { t } = useI18n();
  const generateReportLoading = ref(false);

  const downloadButtonProps = computed(() => {
    const reportStatus = merchant.value.poi_insights_report_status;
    return {
      show: ['in_progress', 'completed'].includes(reportStatus || ''),
      loading: reportStatus === 'in_progress',
      text: reportStatus === 'in_progress' ? t('merchant.poi_insights_report.generating') : t('merchant.poi_insights_report.download')
    };
  });

  const generateReportButtonProps = computed(() => {
    return {
      show: merchant.value.abilities?.generate_poi_insights_report,
      loading: generateReportLoading.value,
      text: merchant.value.poi_insights_report_file?.url ? t('merchant.poi_insights_report.re_generate') : t('merchant.poi_insights_report.generate')
    };
  });

  interface GenerateReportBody {
    product_type: string;
    campaign_category_name: string;
  }

  async function refreshMerchant() {
    const data = await fetchData();
    Object.assign(merchant.value, data);
  }

  async function generateReport(body: GenerateReportBody) {
    generateReportLoading.value = true;
    try {
      const { data } = await request.post<MerchantType>(`/merchants/${merchant.value.id}/generate_poi_insights_report`, body);
      Object.assign(merchant.value, data);
      ElMessageBox.alert(t('merchant.poi_insights_report.generating_success'));
    } finally {
      generateReportLoading.value = false;
    }
  }

  function downloadReport() {
    downloadFromUrl(merchant.value.poi_insights_report_file?.url!, merchant.value.poi_insights_report_file?.filename || 'report.pptx', '_blank');
  }

  let pollingCancel: () => void;
  watch(
    () => merchant.value.poi_insights_report_status,
    (status) => {
      if (status === 'in_progress') {
        pollingCancel?.();
        pollingCancel = polling(refreshMerchant, 5000);
      } else {
        pollingCancel?.();
      }
    },
    { immediate: true }
  );

  onBeforeUnmount(() => {
    pollingCancel?.();
  });

  return {
    generateReport,
    downloadReport,
    generateReportButtonProps,
    downloadButtonProps,
  };
}
